/* General reset to ensure the menu expands beyond any container */
* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  overflow-x: auto; 
}

.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  padding: 20px;
  color: black;
}

.about-us-content {
  max-width: 60%;
}

.text-section {
  margin-bottom: 20px;
}

.image-section {
  text-align: center;
  margin-bottom: 20px;
}

.image-section img {
  max-width: 100%;
  height: auto;
}

.about-us-content h1 {
  font-size: 5rem;
  color: #000000;
  padding-bottom: 1rem;
  align-items: center;
}

.about-us-content p {
  font-size: 18px;
  line-height: 1.6;
}

/* Dropdown Styles */
.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  text-align: left;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.dropdown:hover {
  background-color: #f9f9f9;
}

.dropdown-header {
  flex: 1;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
}

.dropdown-content {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.dropdown-content.open {
  max-height: 500px; /* adjust as needed */
}

.dropdown-text {
  flex: 1;
}

.dropdown-image {
  flex: 1;
  text-align: center;
}

.dropdown-image img {
  max-width: 100%;
  height: 18rem;
}

.dropdown-content p {
  margin: 10px;
  font-size: 16px;
  line-height: 1.5;
}

/* General Navigation Styles */
.about-us-container nav {
  background-color: transparent;
  padding: 1em;
}

#desktop-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  padding: 1em;
}

#desktop-nav .nav-links {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

#desktop-nav .nav-links li {
  list-style: none;
}

#desktop-nav .nav-links a {
  text-decoration: none;
  filter: invert(100%);
  color: black;
  font-size: 1.5rem;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  user-select: none;
}

.nav-links li:hover {
  transform: scale(1.2);
}


#hamburger-nav-about {
  display: none;
}


#desktop-nav .nav-links a:hover {
  color: #007bff;
  transform: scale(1.05); /* Slightly enlarge link on hover */
}



.hamburger-menu-about {
    position: relative;
    z-index: 100000; /* High z-index to ensure it appears on top of other elements */
  }

.hamburger-icon-about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.hamburger-icon-about span {
  background-color: white; /* Change this from white to black */
  width: 100%;
  height: 1px;
  transition: all 0.3s ease-in-out;
  user-select: none;
  z-index: 100000;
}



.menu-links-about a {
  display: block;
  padding: 10px;
  text-align: left;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  user-select: none;
}

.menu-links-about a:hover {
  color: #007bff;
  transform: scale(1.05); /* Slightly enlarge link on hover */
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

#cart-container-about {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  font-size: 0.5rem;
  z-index: 10;
}

#socials-container-about {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.icon:hover {
  transform: scale(1.2); /* Enlarge icon on hover */
}

#logo-container-about {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
}

#logo-container-about .logo {
  filter: invert(100%);
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

#logo-container-about .logo:hover {
  transform: scale(1.1); /* Enlarge logo on hover */
}

#shop-container-about {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
}

/* Media Query Adjustments */
@media (max-width: 768px) {

  body, html {
    height: 100%;
    overflow-y: auto; /* Prevent horizontal overflow */
  }
  #desktop-nav .nav-links {
      display: none;
  }

  #hamburger-nav-about {
      display: flex;
      position: fixed;
      bottom: 20px;
      left: 20px;
      background-color: #000000;
  }

  .menu-links-about a {
      font-size: 1.25rem;
      padding: 8px;
      display: block;
      word-wrap: break-word;
      white-space: normal;
  }

  /* Ensure the menu expands fully on mobile */
  .menu-links-about {
      position: absolute;
      bottom: 40px;
      left: 0;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      color: white;
      background-color: transparent;
      transition: max-height 0.3s ease-in-out;
  }

  .menu-links-about.open {
      max-height: 500px;
  }

  /* Scale the about us content for mobile view */
  .about-us-content {
      max-width: 90%;
      padding: 10px;
  }

  .about-us-content h1 {
      font-size: 2.5rem;
      text-align: center;
  }

  .about-us-content p {
      font-size: 16px;
  }

  /* Adjust logo and icons */
  #logo-container-about .logo {
      width: 80px;
      height: 80px;
  }

  .icon {
      width: 28px;
      height: 28px;
  }

  #cart-container-about {
      top: 10px;
      right: 10px;
  }

  #socials-container-about {
      bottom: 10px;
      right: 10px;
  }

  .dropdown-content.open {
    max-height: 300px; /* Set a max height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  }

  .dropdown-image img {
    max-width: 100%;
    height: 8rem;
  }


  
}