/* Ensure the loading screen covers the entire viewport */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  overflow: hidden; /* Prevent scrollbars */
  z-index: 9999; /* Ensure it is on top of other elements */
}

/* Make sure the video is smaller and centered */
.loading-screen .background-video {
  position: absolute;
  width: 30%;
  height: auto; /* Maintain aspect ratio */
  max-width: 500px; /* Max width for larger screens */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both vertically and horizontally */
  z-index: 0; /* Ensure it stays behind other content */
}

.loading-screen .loading-content {
  z-index: 10;
  text-align: center;
}

.loading-screen p {
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
}

.loading-bar {
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.loading-progress {
  height: 100%;
  background-color: white;
  width: 0%;
  transition: width 0.3s;
}
