.product-display {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  overflow-y: auto;
}

.product-display h2 {
  text-align: center;
  font-size: 2em;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.product {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.product img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 25px;
}

.description {
  flex-grow: 1;
  color: #555;
}

.description h3 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 8px;
}

.description p {
  margin: 5px 0;
  font-size: 1em;
  color: #777;
}

.description h5 {
  margin-top: 15px;
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
}

.total {
  text-align: right;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

button {
  background-color: #ff7b54;
  border: none;
  color: white;
  padding: 15px 40px;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  margin: 30px auto;
  cursor: pointer;
  border-radius: 8px;
  display: block;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff5733;
}

.message {
  text-align: center;
  font-size: 1.5em;
  color: #333;
  margin-top: 50px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
