/* Container styling */
.container-size-guide {
  padding: 50px;
  background-color: white;
  min-height: 100vh;
}

/* Main size guide section */
.main-size-guide-section {
  padding: 20px;
  background-color: #f9f9f9;
  max-height: 600px; /* Adjust this value as needed */
  overflow-y: auto; /* Enables vertical scrolling */
}

/* Size guide heading */
.size-guide-heading {
  font-size: 5rem; /* Large font size for desktop */
  color: #000000;
  padding-bottom: 1rem;
  margin-top: 20px; /* Adjust top margin for spacing */
  text-align: center;
}

/* Size guide images */
.size-guide-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

/* Individual size guide image */
.size-guide-image {
  width: 60%;
  height: auto;
  margin: 10px 0;
}

/* Size chart styling */
.size-chart {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Table cell styling */
.size-chart th,
.size-chart td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Table header styling */
.size-chart th {
  background-color: #f2f2f2;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Adjust container padding for mobile */
  .container-size-guide {
    padding: 20px;
  }

  /* Allow full scrolling on mobile */
  .main-size-guide-section {
    max-height: none; /* Remove the max height limit */
    overflow-y: auto; /* Allow full scrolling */
    padding: 15px;
  }

  /* Smaller font size for the heading on mobile */
  .size-guide-heading {
    font-size: 3rem; /* Smaller font size for mobile */
    margin-top: 10px; /* Adjust top margin for spacing */
    padding-bottom: 0.5rem;
  }

  /* Make images and content larger for mobile */
  .size-guide-images {
    margin-bottom: 20px;
  }

  .size-guide-image {
    width: 90%; /* Make images take up more space on mobile */
  }

  /* Adjust table padding and font size for mobile */
  .size-chart th,
  .size-chart td {
    padding: 12px; /* Increase padding for better touch interaction */
    font-size: 14px; /* Slightly smaller font size for mobile */
  }
}
