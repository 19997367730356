body {
  background-color: #ffffff;
  color: black;
  font-family: 'Arial', sans-serif;
}

.cart-container {
  padding: 30px;
  max-width: 900px;
  margin: auto;
  background-color: #ffffff;
  color: black;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  letter-spacing: 1px;
  color: black;
}

.cart-container p {
  font-size: 16px;
  color: darkslategrey;
}

.basket-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
}

.basket-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.basket-item-image {
  width: 120px;
  height: auto;
  margin-right: 30px;
  border-radius: 4px;
}

.basket-item-details {
  flex-grow: 1;
}

.basket-item-details h3 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: black;
}

.quantity-controls {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.quantity-controls label {
  margin-right: 10px;
  font-size: 16px;
  color: darkslategrey;
}

.quantity-controls input {
  width: 60px;
  padding: 7px;
  border: 1px solid #888888;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  color: black;
}

.price-details {
  margin-top: 15px;
}

.price-details p {
  margin: 5px 0;
  color: black;
  font-size: 16px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.savings {
  color: #28a745;
  font-weight: bold;
}

.remove-button {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 10px;
}

.remove-button:hover {
  background-color: black;
  color: white;
}

.cart-summary {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.cart-summary h3 {
  margin-top: 0;
  font-size: 22px;
  color: black;
}

.cart-summary p {
  margin: 10px 0;
  font-size: 18px;
}

.total-savings {
  color: #28a745;
  font-weight: bold;
}

.checkout-button {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.checkout-button:hover {
  background-color: black;
  color: white;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .basket-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .basket-item-image {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .quantity-controls {
    justify-content: center;
  }
}