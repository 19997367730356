

html, body {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.shop {
    padding: 50px;
    background-color: white;
    min-height: 100vh;
}


.buy-now {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.buy-now h1 {
    font-size: 5rem;
    margin-right: 20px;
    color: #000000;
    padding-bottom: 1rem;
}

.search-bar {
    background-color: #ffffff; 
    color: #000000;
    padding: 5px 10px; 
    font-size: 0.9rem; 
    border-radius: 5px;
    border: 1px solid #000000; 
    position: absolute;
    right: 0;
    width: 200px; 
    margin-left: 20px;
}


.none-found {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    height: 40vh;
    text-align: center;
    font-size: 2rem;
    position: relative;
}

.none-found h1 {
    color: black;
}


.none-found img {
    max-height: 20vh;     
    display: block; 
}

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.product-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    width: 200px; /* Set the width of the card */
    height: 300px; /* Set the height of the card */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .product-card img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the card while maintaining aspect ratio */
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover img {
    transform: scale(1.2); /* Smooth scale-up on hover */
  }
  
  .product-card h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    font-size: 1.2rem;
    z-index: 1; /* Ensures the text appears above the image */
  }
  

#cart-container-shop {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-size: 0.5rem;
    z-index: 10;
    transition: transform 0.3s ease;
}

#cart-container-shop:hover {
    transform: scale(1.2);
}

#cart-container-shop {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-size: 0.5rem;
    z-index: 10;
    transition: transform 0.3s ease;
}

#cart-container-shop:hover {
    transform: scale(1.2);
}


#cart-container-shop {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-size: 0.5rem;
    z-index: 10;
    transition: transform 0.3s ease;
}

#cart-container-shop:hover {
    transform: scale(1.2);
}

    

#cart-container-shop {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-size: 0.5rem;
    z-index: 10;
    transition: transform 0.3s ease;
}

#cart-container-shop:hover {
    transform: scale(1.2);
}

.product-card h2 {
    font-size: 1.2rem;     max-width: 100%;
}

.product-card p {
    font-size: 1rem;     max-width: 100%;
}

  
  .category-nav {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .category-nav button {
    padding: 10px 20px;
    border: 1px solid #ffffff;
    background-color: #000000;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .category-nav button:hover {
    transform: scale(1.05);
    background-color: #868383;
  }
  

nav {
    background-color: transparent;
    padding: 1em;
}

#desktop-nav-shop {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
    padding: 1em;
    filter: invert(100%);
}

#desktop-nav-shop .nav-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

#desktop-nav-shop .nav-links li {
    list-style: none;
}

#desktop-nav-shop .nav-links a {
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
    transition: color 0.3s ease-in-out;
    user-select: none;
    filter: invert(100%);
}

#desktop-nav-shop .nav-links a:hover {
    color: #007bff;
    font-size: xx-large;
    transform: scale(1.5);
}


#socials-container-shop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
}



.icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.icon:hover{
    transform: scale(1.2);

}

#logo-container-shop {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
}

#logo-container-shop a:hover {
    transform: scale(1.05);}

#shop-container-shop {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 2rem;
}

.logo {
    width: 100px;
    height: 100px;
    cursor: pointer;
    filter: invert(100%);
}


@media (max-width: 768px) {
    /* Hide desktop navigation and display hamburger navigation */
    #desktop-nav-shop .nav-links {
        display: none;
    }



    /* Adjust image padding */
    #image-head img {
        padding: 10px;
    }

    /* Adjust cart container for mobile view */
    #cart-container-shop {
        top: 10px;
        right: 10px;
        font-size: 0.75rem;
    }

    /* Adjust socials container for mobile view */
    #socials-container-shop {
        bottom: 10px;
        right: 10px;
    }

    /* Adjust the main shop container */
    #shop-container-shop {
        top: 60%;
        font-size: 1.5rem;
    }

    /* Adjust heading size in shop container */
    #shop-container-shop h1 {
        font-size: 2.5rem;
    }




    /* Adjust the product cards for smaller screens */
    .product-card {
        width: 150px;
        height: 250px;
    }

    .product-card h2 {
        display: none;
        font-size: 1rem;
        padding: 8px;
    }

    .product-card p {
        font-size: 0.9rem;
    }



    /* Adjust category navigation buttons */
    .category-nav button {
        padding: 8px 15px;
        font-size: 0.9rem;
    }

    /* Adjust icon size in the shop container */
    .icon {
        width: 24px;
        height: 24px;
    }
    .buy-now {
        flex-direction: column; /* Stack title and search bar vertically */
        align-items: center; /* Center the elements horizontally */
        padding: 20px; /* Add padding around the container */
        gap: 10px; /* Add space between the title and the search bar */
        width: 100%; /* Ensure full width for proper centering */
        position: relative; /* Ensure elements are positioned correctly */
        height: 100px;
    }

    .buy-now h1 {
       display: none;
    }

    .search-bar {
        background-color: #ffffff; 
        color: #000000;
        padding: 5px 10px; 
        font-size: 0.9rem; 
        border-radius: 5px;
        border: 1px solid #000000; 
        width: 100%; /* Make the search bar take full width of its container */
        max-width: 300px; /* Limit the maximum width to prevent it from becoming too wide */
        box-sizing: border-box;
    }
 
    
}
