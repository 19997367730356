.checkout-result {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .success {
    background-color: #e8f5e9;
    border: 1px solid #4caf50;
  }
  
  .cancel {
    background-color: #ffebee;
    border: 1px solid #f44336;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  .order-actions {
    margin-top: 30px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 10px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .btn-primary {
    background-color: #4caf50;
    color: white;
  }
  
  .btn-secondary {
    background-color: #2196f3;
    color: white;
  }
  
  .btn:hover {
    opacity: 0.8;
  }